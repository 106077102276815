<template>
  <div>
    <Header :headerIndex="5"></Header>
    <div class="container">
      <img class="g_top_banner" src="../assets/static/icon/icon_top_banner5.png" alt="" />
      <div class="contact_wrap">
        <div class="max_1440">
          <!-- <div class="intro">
           {{info.brief}}
          </div> -->

          <div class="contact_box">
            <div class="clearfix">
              <!-- <div class="half_col fl">
                <div class="title">STAY IN TOUCH</div>
                <div class="input_box flex-box">
                  <img class="icon" src="../assets/static/icon/icon_contact1.png" alt="" />
                  <input type="text" @blur="nameval" v-model="name" class="m-input flex-grow-1" placeholder="Please enter your name" />
                </div>
                <div class="input_box flex-box">
                  <img class="icon" src="../assets/static/icon/icon_contact2.png" alt="" />
                  <input @blur="mobileval" v-model="mobile" type="text" class="m-input flex-grow-1" placeholder="Please enter your phone number" />
                </div>
                <div class="input_box flex-box flex-col-start">
                  <img class="icon mt5" src="../assets/static/icon/icon_contact3.png" alt="" />
                  <textarea @blur="sayval" v-model="say" class="m-input flex-grow-1" placeholder="Please enter what you want to say"></textarea>
                </div>
              </div> -->
              <div class="half_col">
                <!-- <div class="title">CONTACT US</div> -->
                <div class="mb20">
                  <span class="key" style="color:#333;font-weight:bold;">RWF Headquarters </span>&nbsp;
                </div>
                <div class="mb20">
                  <span class="key">Website: </span>&nbsp;
                  <span class="value">{{info.website}}</span>
                </div>
                <div class="mb20">
                  <span class="key">Company: </span>&nbsp;
                  <span class="value">{{info.info}}</span>
                </div>
                <div class="mb20">
                  <span class="key">Address: </span>&nbsp;
                  <span class="value">{{info.addr}}</span>
                </div>
                <div class="mb20">
                  <span class="key">Phone: </span>&nbsp;
                  <span class="value">{{info.mobile}}</span>
                </div>
             
                <div> 
                  <span class="key">Email: </span>&nbsp;
                  <span class="value">{{info.email}}</span>
                </div>
              </div>
            </div>
            <!-- <button class="btn_turn_in" @click="togglePop">Submit</button> -->
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <Popup :visible="popVis" @close="close">
      <div class="result_pop">
        <img class="icon_result" src="../assets/static/icon/icon_result.png" alt="" />
        <div class="title">Successfully submitted!</div>
        <router-link to="/" class="btn">Go to the homepage</router-link>
      </div>
    </Popup>
  </div>
</template>

<script>
import axios from 'axios';
import api from '../api';
import { Message } from "element-ui";

export default {
  data() {
    return {
      popVis: false,
      name:'',
      mobile:'',
      say:'',
      info:[]
    };
  },
  methods: {
    //公司详情
    getmenu(){
      api.doGet('/api/banner/getinfo',{},(res)=>{
          console.log(res);
          if(res.code == 200){
            this.info = res.data
          }
      })
    },
   
    togglePop() {
      var name = this.name
      var mobile = this.mobile
      var say = this.say
      if(name&&mobile&&say){
        var json={
          name:name,
          mobile:mobile,
          say:say
        }
        api.doPost('/api/contact/add',json,(res)=>{
          console.log(res)
          if(res.code == 200){
            this.name = ''
            this.mobile = ''
            this.say = ''
          }else if(res.code == 400){
            Message({
              message: "Sending failed, please try again",
              type: "error"
            });
          }
        })
      //提交成功后的弹窗
      this.popVis = !this.popVis;
      }else if(!name){
        Message({
          message: "name is null",
          type: "error"
        });
      }else if(!mobile){
        Message({
          message: "mobile is null!",
          type: "error"
        });
      }else if(!say){
        Message({
          message: "say is null!",
          type: "error"
        });  
      }
    },
    close(){
      // console.log(res)
      this.popVis = !this.popVis;
    },
    //name
    nameval(e){
      // console.log(e.target.value.trim())
      var name = e.target.value.trim()
      if(name){
        this.name = name
      }
    },
    //mobile
    mobileval(e){
      // console.log(e.target.value)
      var mobile = e.target.value
      //号码正则
      var reg = /^[1][3,4,5,7,8][0-9]{9}$/;
      if(reg.test(mobile)){
        this.mobile = mobile
      }else{
        // this.$message.error('请输入正确的电话号码')
        // alert('请输入正确的电话号码!')
        Message({
          message: "请输入正确的电话号码!",
          type: "error"
        });
      }
    },
    //say
    sayval(e){
      // console.log(e.target.value)
      var say = e.target.value.trim()
      if(say){
        this.say = say
      }
    }
  },
  created(){
    this.getmenu()
  }
};
</script>
